import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import { useTranslation } from 'react-i18next';

import ProductCardsAccessoires from "../ProductCardsAccessoires.js"
import Float_SPEC from '../product_spec/Float_SPEC'
import Releaser_SPEC from '../product_spec/Releaser_SPEC'
import AHRS_SPEC from '../product_spec/AHRS_SPEC'
import PRESSURE_SPEC from '../product_spec/Pressure_SPEC'
import PowerON_SPEC from '../product_spec/PowerON_SPEC'
import Flange_SPEC from '../product_spec/Flange_SPEC'
import Wakeup_SPEC from '../product_spec/Wakeup_SPEC'
import Frame_SPEC from '../product_spec/Frame_SPEC'
import Battery_SPEC from '../product_spec/Battery_SPEC'

export default function Accessoires() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  

  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('accessoires.welcome')}</h1>
          <p>{t('accessoires.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('accessoires.principle.title')}</h2>
            <p>{t('accessoires.principle.text_p1')}</p>

            <p><strong>{t('accessoires.principle.c1.name')}</strong> {t('accessoires.principle.c1.description')}</p>
            <p><strong>{t('accessoires.principle.c2.name')}</strong> {t('accessoires.principle.c2.description')}</p>
            <p><strong>{t('accessoires.principle.c3.name')}</strong> {t('accessoires.principle.c3.description')}</p>
          </section>
        </div>

        <div className="usbl-info-container">
          <div>  <ProductCardsAccessoires /> </div>
        </div>

        <section className="usbl-section">
            <h2 id="float">{t('accessoires.float.title')}</h2>
            <div>  <Float_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="releaser">{t('accessoires.releaser.title')}</h2>
            <div>  <Releaser_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="ahrs">{t('accessoires.ahrs.title')}</h2>
            <div>  <AHRS_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="pressure">{t('accessoires.pressure.title')}</h2>
            <div>  <PRESSURE_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="poweron">{t('accessoires.poweron.title')}</h2>
            <div>  <PowerON_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="flange">{t('accessoires.flange.title')}</h2>
            <div>  <Flange_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="wakeup">{t('accessoires.wakeup.title')}</h2>
            <div>  <Wakeup_SPEC /> </div>
        </section>

         <section className="usbl-section">
            <h2 id="frame">{t('accessoires.frame.title')}</h2>
            <div>  <Frame_SPEC /> </div>
        </section>

        <section className="usbl-section">
            <h2 id="battery">{t('accessoires.battery.title')}</h2>
            <div>  <Battery_SPEC /> </div>
        </section>

        <div className="cta-section">
          <h2>{t('accessoires.footer.title')}</h2>
          <p>{t('accessoires.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
        </div>
      </div>
    </div>
  );
}
