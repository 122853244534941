import React, { useState } from 'react';
import { MenuItemsProducts } from './MenuItems';
import './Dropdown.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../i18n';

function DropdownProducts({ closeMenu }) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const { t, i18n } = useTranslation();

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItemsProducts.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path + "#main"}
                onClick={() => {
                  closeMenu(); // Close the menu when clicking a dropdown item
                  setClick(false); // Optionally close the dropdown
                }}
              >
                {t('navbar.product_items.' + item.name)}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DropdownProducts;
