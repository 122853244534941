import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import DropdownProducts from './DropdownProducts';
import DropdownLanguage from './DropdownLanguage';

import LatenaLogo from '../assets/logo-for-dark.svg';
import { useTranslation } from 'react-i18next';
import '../i18n';

function Navbar() {
  const { t, i18n } = useTranslation();

  const [click, setClick] = useState(false);
  const [dropdownProducts, setDropdownProducts] = useState(false);
  const [dropdownLanguage, setDropdownLanguage] = useState(false);

  const handleClick = () => setClick(!click);

  // Close the mobile menu on click
  const closeMobileMenu = () => setClick(false);

  const toggleDropdownProducts = () => {
    setDropdownProducts(!dropdownProducts);
    if (dropdownLanguage) setDropdownLanguage(false); // Close language dropdown if open
  };

  const toggleDropdownLanguage = () => {
    setDropdownLanguage(!dropdownLanguage);
    if (dropdownProducts) setDropdownProducts(false); // Close products dropdown if open
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      // Close the menu when the language changes
      if (click) closeMobileMenu();
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup event listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [click, i18n]);

  return (
    <>
      <nav className="navbar">
        <Link to="/#main" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={LatenaLogo} width="70" alt="AO Latena" />
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              {t('navbar.home')}
            </Link>
          </li>

          <li className="nav-item" onClick={toggleDropdownProducts}>
            <Link className="nav-links">
              {t('navbar.products')} <i className="fas fa-caret-down" />
            </Link>
            {dropdownProducts && <DropdownProducts closeMenu={closeMobileMenu} />}
          </li>

          <li className="nav-item">
            <Link to="/contact#main" className="nav-links" onClick={closeMobileMenu}>
              {t('navbar.contact')}
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/career#main" className="nav-links" onClick={closeMobileMenu}>
              {t('navbar.career')}
            </Link>
          </li>

          <li className="nav-item" onClick={toggleDropdownLanguage}>
            <Link className="nav-links">
              {t('navbar.language')} <i className="fas fa-caret-down" />
            </Link>
            {dropdownLanguage && <DropdownLanguage closeMenu={closeMobileMenu} />}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
